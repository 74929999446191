import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Wrapper = styled.div`
  max-width: 1170px;
  display: grid;
  grid-template-columns: 491px auto;
  grid-template-rows: auto auto auto 1fr;
  grid-column-gap: 91px;
  grid-row-gap: 35px;
  grid-template-areas:
    "header image"
    "address image"
    "chat image"
    "space image";
  padding: 165px 0 133px;
  margin: 0 auto 123px;

  @media screen and ${device.tablet} {
    grid-template-columns: 100%;
    grid-template-areas: "header" "address" "chat" "image";
    grid-row-gap: 25px;
    padding: 115px 24px 37px;
    margin-bottom: 80px;
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 0;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
  }
`;

const Image = styled.img`
  max-width: 600px;
  display: inline-block;
  width: 100%;
  grid-area: image;

  @media screen and ${device.tablet} {
    margin-top: 31px;
  }
`;

const AddressBlock = styled.div`
  border-radius: 11px;
  background-color: #0089ff;
  font-weight: 500;
  font-size: 18px;
  color: white;
  max-width: 371px;
  width: 100%;
  box-sizing: border-box;
  padding: 27px 24px 0;
  grid-area: address;

  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and ${device.tablet} {
    font-size: 16px;
    padding: 24px 24px 0;
  }
`;

const ChatIcon = styled.i<{
  url: string;
}>`
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin-right: 15px;
  background-image: url(${props => props.url});
`;

export const TitleSection = (props: {
  title: string;
  image: string;
  alt: string;
  addressTitle: string;
  addressBlocks: Array<{
    icon: string;
    text: string;
  }>;
  chatIcon: string;
  chatText: string;
}): JSX.Element => {
  return (
    <section style={{
      background: `url(${Background}) no-repeat bottom center`,
      backgroundSize: 'cover',
    }}>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <AddressBlock>
          <header style={{marginBottom: 19}}><ReactMarkdown>{props.addressTitle}</ReactMarkdown></header>
          <ul>
            {
              props.addressBlocks.map(({text, icon}, index) => (
                <li key={index} style={{marginBottom: 31}}>
                  <img
                    style={{display: 'inline-block', verticalAlign: 'top', marginRight: 25, marginTop: '0.3em'}}
                    src={icon}
                    alt=""
                  />
                  <div style={{display: 'inline-block'}}><ReactMarkdown>{text}</ReactMarkdown></div>
                </li>
              ))
            }
          </ul>
        </AddressBlock>
        <span style={{
          display: 'inline-block',
          textTransform: 'uppercase',
          fontWeight: 600,
          fontSize: 14,
          color: 'white',
          gridArea: 'chat'
        }}>
          <ChatIcon url={props.chatIcon} />
          <div style={{display: 'inline-block'}}>
            <ReactMarkdown>{props.chatText}</ReactMarkdown>
          </div>
        </span>
        <Image src={props.image} alt={props.alt}/>
      </Wrapper>
    </section>
  );
};
