import * as React from 'react';
import { graphql } from 'gatsby';
import '../styles.scss';
import {BasePageData, BasePageFooter, BasePageHeader} from '../../helpers/base-page';
import {MarkdownRemark} from '../../models/markdown-remark';
import {TitleSection} from '../../components/learn/title-section';
import styled from 'styled-components';
import {BackgroundFigure} from '../../components/shared/background-figure';
import {device} from '../../models/devices';

interface LearnAboutPageData extends BasePageData {
  learnAboutData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    heroImage: string;
    altText: string;
    adressBoxTitle: string;
    adressList: Array<{
      icon: string;
      text: string;
    }>;
    chat: {
      icon: string;
      title: string;
    };
    subSections: Array<{
      type: 'meetingSection';
      title: string;
      embedded: string;
    } | {
      type: 'connectSection';
      title: string;
      conectItems: Array<{
        text: string;
        link: string;
        icon: string;
        buttonText: string;
      }>
    }>;
  }>;
}

const ConnectItem = styled.div`
  width: 270px;
  height: 212px;
  background: #FFFFFF;
  box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 22px;
  box-sizing: border-box;
  
  a {
    text-decoration: none;
    background: #4231a9;
    color: white;
    align-self: stretch;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 0;
  }

  @media screen and ${device.tablet} {
    margin-bottom: 24px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const SectionHeader = styled.header`
  font-size: 48px;
  color: #4f40ab;
  text-align: center;
  margin-bottom: 49px;
  font-weight: 500;
  
  @media screen and ${device.tablet} {
    font-size: 24px;
    line-height: 1.5em;
    margin-bottom: 32px;
    padding: 0 24px;
  }
`;

const Section = styled.section`
  margin-bottom: 120px;
  
  @media screen and ${device.tablet} {
    margin-bottom: 80px;
  }
`;

const ConnectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 870px;
  margin: 0 auto;
  
  @media screen and ${device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const LearnAboutPage = ({data}: {data: LearnAboutPageData}): JSX.Element => {

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.learnAboutData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
      />
      <TitleSection
        title={data.learnAboutData.edges[0].node.frontmatter.title}
        image={data.learnAboutData.edges[0].node.frontmatter.heroImage}
        alt={data.learnAboutData.edges[0].node.frontmatter.altText}
        addressBlocks={data.learnAboutData.edges[0].node.frontmatter.adressList}
        addressTitle={data.learnAboutData.edges[0].node.frontmatter.adressBoxTitle}
        chatText={data.learnAboutData.edges[0].node.frontmatter.chat.title}
        chatIcon={data.learnAboutData.edges[0].node.frontmatter.chat.icon}
      />
      {
        data.learnAboutData.edges[0].node.frontmatter.subSections.map((section, index) => {
          switch (section.type) {
            case 'connectSection': {
              return (
                <Section key={index}>
                  <SectionHeader>{section.title}</SectionHeader>
                  <ConnectionContainer>
                    {
                      section.conectItems.map((item, j) => (
                        <ConnectItem key={j}>
                          <header style={{fontSize: 18, fontWeight: 600}}>{item.text}</header>
                          <img src={item.icon} alt=""/>
                          <a href={item.link}>
                            {item.buttonText}
                          </a>
                        </ConnectItem>
                      ))
                    }
                  </ConnectionContainer>
                </Section>
              );
            }
            case 'meetingSection': {
              return (
                <Section key={index} id={`meeting-${index}`} style={{
                  scrollSnapMarginTop: 90,
                  scrollMarginTop: 90
                }}>
                  <SectionHeader>{section.title}</SectionHeader>
                  <div
                    style={{maxWidth: 872, width: '100%', margin: '0 auto'}}
                    dangerouslySetInnerHTML={{__html: section.embedded}}
                  />
                </Section>
              );
            }
            default: {
              return '';
            }
          }
        })
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 86, height: 218}}
          position={{left: 0, top: 950}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 36, height: 72}}
          position={{right: 0, top: 828}}
          style={{
            zIndex: 0,
            borderRadius: 36,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <div style={{
          position: 'absolute',
          left: 0,
          top: 1350,
          width: 86,
          height: 155,
          overflow: 'hidden'
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#dee4fb'}
            size={{width: 60, height: 60}}
            position={{right: 30, bottom: 37}}
            style={{
              transform: `
                rotate(51deg)
                skewX(-30deg)
                scale(1,.866)
              `
            }}
          />
        </div>
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default LearnAboutPage;

export const pageQuery = graphql`
  {
    ...BaseData
    learnAboutData: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/\\/learn\\/about.md$/"}}) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            heroImage
            altText
            adressBoxTitle
            adressList {
              icon
              text
            }
            chat {
              icon
              title
            }
            subSections {
              type
              title
              embedded
              conectItems {
                text
                link
                icon
                buttonText
              }
            }
          }
        }
      }
    }
  }
`;
